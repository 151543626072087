<template>
  <Modal
    name="user-agreement"
    align="center"
    ref="userAgreement"
    :closable="false"
    hide-close
  >
    <div class="modal__heading modal__item">
      <div class="modal__top-img">
        <img :src="`/Content/vue/public/img/themes/${$tenant}/accept-terms-rafiki.svg`" alt="">
      </div>
      <h3 class="modal__title">{{ $t('userAgreementTitle') }}</h3>
      <div class="modal__subtitle">{{ $t('userAgreementSubtitle1') }}
        <a class="link"
          href="/Content/files/сontract-offer-user-platform.pdf"
          target="_blank">
          {{ $t('userAgreementSubtitle2') }}
        </a>, <span v-html="$t('userAgreementSubtitle3')"></span></div>
    </div>
    <div class="modal__item">
      <base-button
        class="button_family_primary button_type_standart button_color_primary signin ml-auto mr-auto"
        size="large"
        :loading="agreement.sending"
        :action="acceptAgreement"
      >
        {{ $t('userAgreementButton') }}
      </base-button>
    </div>
  </Modal>
</template>

<script>
import axios from 'axios';
import Modal from '@/components/Modal.vue';
import BaseButton from "./../../../assets/components/BaseButton";

export default {
  name: 'AgreementModal',
  components: {
    Modal,
    BaseButton,
  },
  data() {
    return {
      agreement: {
        sending: false,
      },
    };
  },
  methods: {
    acceptAgreement() {
      if (this.agreement.sending) {
        return;
      }
      this.agreement.sending = true;
      axios.post('/Account/ApplyAgreement')
        .then((response) => {
          if (response.status === 200) {
            this.$emit('accepted');
          }
        })
        .catch(() => {
          this.agreement.sending = false;
        });
    },
    open() {
      this.$refs.userAgreement.open();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal::before {
  content: none;
}
</style>
