import Vue from 'vue'
import App from './Login.vue'
import i18n from './i18n'
import Vuelidate from 'vuelidate'

Vue.use(Vuelidate);

Vue.config.productionTip = false;
Vue.prototype.$vm = window.vm;
i18n.locale = window.__LANGUAGE__ ?? 'ru';

const globalData = new Vue({
    data: { $tenant: null },
});

setTimeout(() => {
    globalData.$data.$tenant = window.__Tenant__ || 'RR';
});

Vue.mixin({
    computed: {
        $tenant: {
            get: () => globalData.$data.$tenant,
            set: (val) => { globalData.$data.$tenant = val; },
        },
    },
});

new Vue({
    i18n,
    render: h => h(App)
}).$mount('#login');
